/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CacheService } from '@core/cache/cache.service';
import { MerchantService } from '@core/merchant/merchant.service';
import { Merchant } from '@core/merchant/merchant.types';
import { environment } from '@env/environment';
import { BehaviorSubject, catchError, Observable, of, switchMap, take, tap } from 'rxjs';
import { AdminInvitation } from './admin-invitation.types';
import { DEFAULT_HTTP_OPTIONS_NO_EXPIRE, HttpOptions } from '@core/utils/http-options.types';

/**
 * Service for managing admin invitations for a merchant.
 */
@Injectable({
  providedIn: 'root'
})
export class AdminInvitationService {

  /**
   * The merchant associated with the admin invitations.
   */
  private _merchant: Merchant;

  /**
   * The list of admin invitations, as a BehaviorSubject.
   */
  private _adminInvitations: BehaviorSubject<AdminInvitation[]> = new BehaviorSubject(null);

  /**
   * The cache namespace.
   */
  private _cacheNamespace: string = 'admin-invitation';

  /**
   * Constructor.
   * 
   * @param _httpClient The HttpClient service.
   * @param _merchantService The MerchantService.
   * @param _cacheService The CacheService.
   */
  constructor(
    private _httpClient: HttpClient,
    private _merchantService: MerchantService,
    private _cacheService: CacheService,
  ) {
    // Get the merchant
    this._merchantService.merchant$
      .subscribe((merchant: Merchant) => {
        const reload = this._merchant?._id?.toString() !== merchant?._id?.toString();
        this._merchant = merchant;
        if (merchant && reload) {
          this._cacheService.delete(this._cacheNamespace, 'invitations', { merchantId: this._merchant._id });
          this.getAdminInvitations().subscribe();
        }
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an observable of the current list of admin invitations.
   * @returns An observable of the current list of admin invitations.
   */
  get adminInvitations$(): Observable<AdminInvitation[]> {
    return this._adminInvitations.asObservable();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Returns an array of AdminInvitation objects.
   * @param options - The options related to cache and propagation.
   * @returns An array of AdminInvitation objects.
   */
  getAdminInvitations(options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<AdminInvitation[]> {
    // If there is no merchant, return an empty array
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of([]);
    }
    // If the admin invitations are already loaded, return them
    const cacheParams: any = { merchantId: this._merchant._id };
    if (options.cache && this._cacheService.has(this._cacheNamespace, 'invitations', cacheParams)) {
      const adminInvitations: AdminInvitation[] = this._cacheService.get(this._cacheNamespace, 'invitations', cacheParams);
      if (options.forcePropagate) {
        this._adminInvitations.next(adminInvitations);
      }
      return of(adminInvitations);
    }
    // Otherwise, load the admin invitations from the backend
    return this._httpClient.get<AdminInvitation[]>(
      `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/admin-invitations`,
      {
        headers: new HttpHeaders().set('Content-Type', 'application/json'),
        withCredentials: true
      })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(`Error: ${error}`);
          return of([]);
        }),
        // Handle the logic of the cache and the propagation
        tap((adminInvitations: AdminInvitation[]) => {
          if (options.cache) {
            this._cacheService.set(this._cacheNamespace, 'invitations', cacheParams, adminInvitations, options.expire);
          }
          if (options.propagate) {
            this._adminInvitations.next(adminInvitations);
          }
        })
      );
  }

  /**
   * Adds an admin invitation for the given email address.
   * @param email The email address of the user to be invited.
   * @param options - The options related to cache and propagation.
   * @returns The added AdminInvitation object.
   */
  addAdminInvitation(email: string, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<AdminInvitation> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.adminInvitations$.pipe(
      take(1),
      switchMap((adminInvitations: AdminInvitation[]) => this._httpClient.post<AdminInvitation>(
        `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/admin-invitations`,
        { email },
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error: ${error}`);
            return of(null);
          }),
          // Handle the logic of the cache and the propagation
          tap((addedAdminInvitation: AdminInvitation) => {
            console.log('addedAdminInvitation', addedAdminInvitation);
            console.log('options', options);
            if (!addedAdminInvitation) {
              return;
            }
            const cacheParams: any = { merchantId: this._merchant._id };
            if (options.cache) {
              this._cacheService.set(this._cacheNamespace, 'invitations', cacheParams, [addedAdminInvitation, ...adminInvitations], options.expire);
            }
            if (options.propagate) {
              this._adminInvitations.next([addedAdminInvitation, ...adminInvitations]);
            }
          })
        )
      )
    );
  }

  /**
   * Deletes an admin invitation.
   * @param adminInvitation - The admin invitation to be deleted.
   * @param options - The options related to cache and propagation.
   * @returns The deleted admin invitation.
   */
  deleteAdminInvitation(adminInvitation: AdminInvitation, options: HttpOptions=DEFAULT_HTTP_OPTIONS_NO_EXPIRE): Observable<AdminInvitation> {
    if (!this._merchant) {
      console.log('Error: No merchant.');
      return of(null);
    }
    return this.adminInvitations$.pipe(
      take(1),
      switchMap((adminInvitations: AdminInvitation[]) => this._httpClient.delete<AdminInvitation>(
        `${environment.qart.apiUrl}/${environment.qart.apiVersion}/merchants/${this._merchant._id}/admin-invitations/${adminInvitation._id}`,
        {
          headers: new HttpHeaders().set('Content-Type', 'application/json'),
          withCredentials: true
        })
        .pipe(
          catchError((error: HttpErrorResponse) => {
            console.error(`Error: ${error}`);
            return of(null);
          }),
          // Handle the logic of the cache and the propagation
          tap((deletedAdminInvitation: AdminInvitation) => {
            if (!deletedAdminInvitation) {
              return;
            }
            const index = adminInvitations.findIndex(item => item._id === adminInvitation._id);
            if (index >= 0) {
              adminInvitations.splice(index, 1);
              const cacheParams: any = { merchantId: this._merchant._id };
              if (options.cache) {
                this._cacheService.set(this._cacheNamespace, 'invitations', cacheParams, adminInvitations, options.expire);
              }
              if (options.propagate) {
                this._adminInvitations.next(adminInvitations);
              }
            }
          })
        )
      )
    );
  }

}
